import { computed, watch } from 'vue'
import { kpiMin as performanceMin, kpiMax as performanceMax, kpiAvg as performanceAvg } from './common'

// OLD CODE from: https://github.com/100-m/100m-platform/blob/cedrus/src/utils/sugar.js#L115
const rolling_window = (arr, windowsize) => arr.slice(windowsize || 1).map((d, i) => [arr[i], d])
const std_var = (series, mean) => {
  mean = mean || d3.mean(series)
  const deviation = series.map(d => (d - mean) ** 2)
  return Math.sqrt(d3.sum(deviation) / deviation.length)
}
const volatility = (series, n) => {
  const returns = rolling_window(
    series.filter(d => d != 0),
    7,
  ).map(d => d[1] / d[0] - 1)
  return std_var(returns) * Math.sqrt(n || 52) || 0
}

const isCurrent_account = isin => {
  return $root.x.mapping.find(d => d.isin == isin)?.override
}

const line_returns_movements = (isin, first_day, last_day) => {
  if (isCurrent_account(isin) == 'True') return 0
  const alloc_domain = $root.x.allocation.filter(d => d.isin === isin && d.date >= first_day && d.date <= last_day)
  const alloc_d0 = alloc_domain.first()
  const position_d0 = alloc_d0.price * alloc_d0.quantity
  const alloc_d1 = alloc_domain.last()
  const position_d1 = alloc_d1.price * alloc_d1.quantity
  const movements = $root.x.movements.filter(d => d.isin === isin && d.date >= first_day && d.date <= last_day)
  let position_before_movement = position_d0
  const ratios = movements.map((movement) => {
    const delta_position = movement.delta_position
    const position_after_movement = alloc_domain.find(d => d.date === movement.date).position || 0
    const ratio = position_before_movement == 0 ? 1 : (position_after_movement - delta_position) / position_before_movement
    position_before_movement = position_after_movement
    return ratio
  })
  const ratio_d1 = position_before_movement == 0 ? 1 : position_d1 / position_before_movement

  return [...ratios, ratio_d1].reduce((acc, val) => acc * val, 1) - 1
}

const line_returns = (isin, first_day, last_day) => {
  if (isCurrent_account(isin) == 'True') return 0
  var trades = JSON.parse(JSON.stringify($root.x.trades.filter(d => d.isin === isin).filter(d => d.date_start <= last_day && d.date_end >= first_day)))
  if (trades.length === 0) return 0
  trades.forEach(d => {
    if (d.date_start < first_day && d.date_end != first_day)
      d.price_start = $root.xf.contribution.data.filter(d => d.isin === isin && d.date >= first_day).first().price
    if (d.date_end > last_day) d.price_end = $root.xf.contribution.data.filter(d => d.isin === isin && d.date <= last_day).last().price
    if (d.date_start < first_day && d.date_end === first_day) d.price_start = d.price_end
  })
  const pnl = trades.__.map(d => (d.price_end - d.price_start) * d.quantity).sum()
  return pnl / trades.__.map(d => d.price_start * d.quantity).sum()
}
export default function useDetailsGraphs() {
  //const action = computed(() => { return $root.action })
  //const downloadable = computed(() => { return $root.action && $root.action === 'download' })
  const downloadable = computed(() => {
    return $root.query && $root.query.action === 'download'
  })
  const first = computed(() => {
    return new Date($root.domain.first()) - new Date($root.x.contribution.first().date) > 0 ? $root.domain.first() : $root.x.contribution.first().date
  })
  const last = computed(() => {
    return new Date($root.domain.last()) - new Date($root.x.contribution.last().date) < 0 ? $root.domain.last() : $root.x.contribution.last().date
  })

  const first_day_plus_1 = computed(() => {
    const d = new Date($root.domain.first()) - new Date($root.x.contribution.first().date) > 0 ? $root.domain.first() : $root.x.contribution.first().date
    const d_next = new Date(d)
    d_next.setDate(d_next.getDate() + 1)
    return d_next.toISOString().split('T')[0]
  })

  const lastMonthFirst = computed(() => {
    const now = new Date($root.domain.last())
    var d = new Date(now.getFullYear(), now.getMonth(), 1).format()
    if (d < $root.x.performance.dates.__.keys().first()) return $root.x.performance.dates.__.keys().first()
    while (!$root.x.performance.dates.__.keys().includes(d)) {
      d = new Date(d).minus('1 day').format()
    }
    return new Date(d).format()
  })

  const lastMonthFirstAfter = computed(() => {
    var d = new Date(lastMonthFirst.value).plus('2 day').format()
    while (!$root.x.performance.dates.__.keys().includes(d)) {
      if (d > $root.x.performance.dates.__.keys().last()) return $root.x.performance.dates.__.keys().last()
      d = new Date(d).plus('1 day').format()
    }
    return d
  })

  const lastMonthLast = computed(() => {
    const now = new Date($root.domain.last()).plus('1 day')
    var d = new Date(now.getFullYear(), now.getMonth(), 1).format()
    if (d < $root.x.performance.dates.__.keys().first()) return $root.x.performance.dates.__.keys().first()
    do {
      d = new Date(d).minus('1 day').format()
    } while (!$root.x.performance.dates.__.keys().includes(d))
    return new Date(d).format()
  })

  const lastMonthLastAfter = computed(() => {
    var d = new Date(lastMonthLast.value).plus('2 day').format()
    while (!$root.x.performance.dates.__.keys().includes(d)) {
      if (d > $root.x.performance.dates.__.keys().last()) return $root.x.performance.dates.__.keys().last()
      d = new Date(d).plus('1 day').format()
    }
    return d
  })

  const PreviousFiveYearsBegin = computed(() => {
    const now = new Date($root.domain.last())
    var d = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate()).format()
    if (d < $root.x.performance.dates.__.keys().first()) return $root.x.performance.dates.__.keys().first()
    while (!$root.x.performance.dates.__.keys().includes(d)) {
      d = new Date(d).minus('1 day').format()
    }
    return new Date(d).format()
  })

  const slicesFirst = computed(() => {
    return $root.x.contribution.slice($root.x.contribution.dates[lastMonthFirst.value], $root.x.contribution.dates[lastMonthFirstAfter.value])
  })

  //If today is July 26th, we take all the contributions.dates between June 30 2022 and July 2 2022 (July 2 excluded)
  const slicesLast = computed(() => {
    return $root.x.contribution.slice($root.x.contribution.dates[lastMonthLast.value], $root.x.contribution.dates[lastMonthLastAfter.value])
  })

  const slicesLastYear = computed(() => {
    return $root.x.contribution.slice(
      $root.x.contribution.dates[last.value.slice(0, 4) - 1 + '-12-31'],
      $root.x.contribution.dates[last.value.slice(0, 4) + '-01-01'],
    )
  })

  const table = computed(() => {
    const line_volatility = isin => {
      return volatility($root.xf.contribution.data.__.filter(d => d.isin == isin && Math.abs(d.position) > 0.001).map('price'))
      // NEW CODE
      // return $root.xf.contribution.data.__.filter(d => d.isin == isin && Math.abs(d.position) > 0.001).volatility('price')
    }
    const caceis_isin = isin => {
      return mapping[mapping.__.find(m => m.isin === isin)]?.['caceis_isin']
    }
    const manager = isin => {
      return mapping[mapping.__.find(m => m.isin === isin)]?.['gerant']
    }
    const isr = isin => {
      return mapping[mapping.__.find(m => m.isin === isin)]?.['isr']
    }
    const zone_geo = isin => {
      return mapping[mapping.__.find(m => m.isin === isin)]?.['zone_geo']
    }
    const secteur = isin => {
      return mapping[mapping.__.find(m => m.isin === isin)]?.['secteur']
    }
    const devise = isin => {
      return mapping[mapping.__.find(m => m.isin === isin)]?.['devise']
    }

    const line_volatility_yearly = isin => {
      if (isCurrent_account(isin) == 'True') return 0
      const now = new Date($root.domain[1])
      const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())
      const raw_data_list = $root.x.contribution
        .filter(d => d.isin == isin)
        .filter(d => d.date >= start_date.format())
        .filter(d => d.position != 0 || (d.position == 0 && d.delta != 0))
        .group('date')
      const sum_list = raw_data_list.__.map((current, date) => ({ date: date, sum_position: current.sum('position'), sum_delta: current.sum('delta') }))
      var vl_day = 100
      var perf_day = 0
      const perf_list = sum_list.__.map((current, date, index) => {
        if (index == 0) {
          return { date: current.date, performance: 0, vl: vl_day }
        } else {
          perf_day = (current.sum_position - current.sum_delta) / sum_list.__.v()[index - 1].sum_position - 1
          vl_day *= 1 + perf_day
          return { date: current.date, performance: perf_day, vl: vl_day }
        }
      })

      const filtered_perf_list = perf_list.__.filter(d => d.date >= start_date.format())
        .__.filter(d => new Date(d.date).getDay() === now.getDay())
        .__.map(d => d.vl)
      return (
        filtered_perf_list.__.map((current, date, index) => (index == 0 ? 0 : current / filtered_perf_list.__.v()[index - 1] - 1))
          .__.v()
          .slice(1)
          .std(1) * Math.sqrt(52)
      )
    }

    const aum_last_year = isin => {
      const data_last_year = slicesLastYear.value.__.filter(d => d.isin == isin)
      return data_last_year.length && data_last_year.__.first().position
    }
    const line_benchmark_last_month = isin => {
      if (isCurrent_account(isin) == 'True') return 0
      var f = $root.xf.contribution.data.__.filter(d => d.isin == isin)
      const first = f.__.find(d => d.date === lastMonthFirst.value).benchmark || 1
      const last = f.__.last().benchmark || 1
      return last / first - 1
    }

    const period_return_type = (type, first_day, last_day) => {
      var trades = JSON.parse(JSON.stringify($root.x.trades.filter(d => d.type === type).filter(d => d.date_start <= last_day && d.date_end >= first_day)))
      if (trades.length === 0) return 0
      trades.forEach(trade => {
        if (trade.date_start < first_day && trade.date_end != first_day)
          trade.price_start = $root.xf.contribution.data.filter(d => d.isin === trade.isin && d.date >= first_day).first().price
        if (trade.date_end > last_day) trade.price_end = $root.xf.contribution.data.filter(d => d.isin === trade.isin && d.date <= last_day).last().price
        if (trade.date_start < first_day && trade.date_end === first_day) trade.price_start = trade.price_end
      })
      const pnl = trades
        .filter(d => d.override == 'False')
        .__.map(d => (d.price_end - d.price_start) * d.quantity)
        .sum()
      return pnl / trades.__.map(d => d.price_start * d.quantity).sum()
    }

    const returns_type_last_month = (type, first_day) => {
      const data = $root.xf.contribution.data.filter(d => d.type == type).filter(d => d.date >= first_day && d.position !== 0 && d.override != 'True')
      if (data.length === 0) return 0.0
      const pnl = data.__.sum('pnl')
      return pnl / data.group('date')[data[0].date].sum('position')
    }

    const line_benchmark = isin => {
      if (isCurrent_account(isin) == 'True') return 0
      var f = $root.xf.contribution.data.__.filter(d => d.isin == isin)
      const first = f.__.first().benchmark || 1
      const last = f.__.last().benchmark || 1
      return last / first - 1
    }

    const line_pnl_latent_raw = (isin, pnl_latent_raw) => {
      return isCurrent_account(isin) == 'True' ? 0 : pnl_latent_raw
    }

    const vl0 = $root.xf.performance.data.first().nav_before
    const group_order = [
      'liquidity',
      'savings_account',
      'monetary',
      'monetary_opcvm',
      'term_deposit',
      '_3_6_months',
      '_1_year',
      'bonds',
      'disintermediation',
      'capitalization_contract',
      'remunerated_account',
      'prudent',
      'monetary_stable',
      '_1_3_years',
      '_3_5_years',
      'prudent',
      'short_term_obligation',
      'diversified_balanced',
      'middle_term',
      'middle_long_term',
      '_5_years',
      'stable',
      'alternative_investment',
      'matieres_premieres',
      'ucits_equity',
      'stocks',
      'real_estate',
      'hedge_fund',
      'adossee',
      'heart',
      'diversification',
      'diversified',
      'multi_strategy',
      'fcpr',
      'diversified_real_assets',
      'side_pockets',
      'other',
      'na',
    ]
    const aum_last = $root.xf.performance.data.__.filter(d => d.date == $root.domain[1]).__.first().aum

    const table = $root.xf.contribution.name.__.filter(d => d != null && d.isin !== '497437A7-AFA9-49A7-B40C-661BBE47A953')
      .__.map((v, k) => ({
        name: k,
        isin: v.isin,
        type: v.type,
        caceis_isin: v.type === 'liquidity' ? 'CASH' : caceis_isin(v.isin),
        get returns() {
          return line_returns_movements(v.isin, first_day_plus_1.value, last.value)
        },
        get returns_last_month() {
          return line_returns_movements(v.isin, lastMonthFirst.value, last.value)
        },
        get returns_5y() {
          return line_returns_movements(v.isin, PreviousFiveYearsBegin.value, last.value)
        },
        get benchmark() {
          return line_benchmark(v.isin)
        },
        get benchmark_last_month() {
          return line_benchmark_last_month(v.isin)
        },
        get aum_last_year_raw() {
          return aum_last_year(v.isin)
        },
        manager: v.type === 'liquidity' ? 'CASH' : manager(v.isin),
        position: v.position_last,
        liquidity: v.liquidity,
        get volatility() {
          return line_volatility(v.isin)
        },
        get volatility_yearly() {
          return line_volatility_yearly(v.isin)
        },
        allocation: v.position_last / aum_last,
        pnl: isCurrent_account(v.isin) == 'True' ? 0 : v.pnl,
        contribution: isCurrent_account(v.isin) == 'True' ? 0 : v.pnl_wallet_shares / vl0,
        pnl_realised: v.pnl_realised,
        pnl_latent: line_pnl_latent_raw(v.isin, v.pnl_latent),
        pnl_realised_raw: isCurrent_account(v.isin) == 'True' ? 0 : v.pnl_realised,
        pnl_latent_raw: line_pnl_latent_raw(v.isin, v.pnl_latent),
        v_ff: v,
        isr: v.type === 'liquidity' ? 'CASH' : isr(v.isin),
        zone_geo: v.type === 'liquidity' ? 'CASH' : zone_geo(v.isin),
        secteur: v.type === 'liquidity' ? 'CASH' : secteur(v.isin),
        devise: v.type === 'liquidity' ? 'EUR' : devise(v.isin),
        quantity: +v.quantity,
      }))
      .__.filter(d => d.position != 0 || d.pnl != 0)
      .__.v()
      .sort([d => group_order.indexOf(d.type), 'name'])
      .group('type')
      .__.v()
      .__.map(d => {
        return {
          group: d[0].type,
          position: d.sum('position'),
          allocation: d.sum('allocation'),
          returns: period_return_type(d[0].type, first_day_plus_1.value, last.value),
          returns_last_month: returns_type_last_month(d[0].type, lastMonthFirst.value),
          get benchmark() { d.map('benchmark').mean() },
          get benchmark_last_month() { d.map('benchmark_last_month').mean() },
          pnl: d.sum('pnl'),
          contribution: d.sum('contribution'),
          pnl_realised_raw: d.sum('pnl_realised_raw'),
          pnl_latent_raw: d.sum('pnl_latent_raw'),
          data: d,
        }
      })
    return table
  })

  const volatility_yearly_poche = d => {
    const now = new Date($root.domain[1])
    const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate())
    let isins, raw_data
    if (!d) {
      raw_data = $root.x.contribution
        .filter(d => d.date >= start_date.format())
        .filter(d => d.position != 0 || (d.position == 0 && d.delta != 0))
        .group('date')
    } else {
      isins = d.data.map('isin')
      raw_data = $root.x.contribution
        .filter(d => isins.includes(d.isin))
        .filter(d => d.date >= start_date.format())
        .filter(d => d.position != 0 || (d.position == 0 && d.delta != 0))
        .group('date')
    }
    const filtered_liq = raw_data.__.map(d => d.filter(e => e.override == 'True'))
    const sum_filtered_liq = filtered_liq.__.map((current, date, index) => {
      if (index == 0) {
        return { date: current.date, sum_delta_liq: 0 }
      } else {
        return { date: date, sum_delta_liq: current.sum('position') - filtered_liq.__.v()[index - 1].sum('position') }
      }
    })

    const sum_list = raw_data.__.map((current, date, index) => ({
      date: date,
      sum_position: current.sum('position'),
      sum_delta_others: current.filter(d => d.override == 'False').sum('delta'),
    }))
    var vl_day = 100
    var perf_day = 0
    const perf_list = sum_list.__.map((current, date, index) => {
      if (index == 0) {
        return { date: current.date, performance: 0, vl: vl_day }
      } else {
        perf_day =
          (current.sum_position - current.sum_delta_others - sum_filtered_liq.__.v()[index].sum_delta_liq) / sum_list.__.v()[index - 1].sum_position - 1
        vl_day *= 1 + perf_day
        return { date: current.date, performance: perf_day, vl: vl_day }
      }
    })
    const filtered_perf_list = perf_list.__.filter(d => d.date >= start_date.format() && d.date <= $root.domain[1])
      .__.filter(d => new Date(d.date).getDay() === now.getDay())
      .__.map(d => d.vl)
    return (
      filtered_perf_list.__.map((current, date, index) => (index == 0 ? 0 : current / filtered_perf_list.__.v()[index - 1] - 1))
        .__.v()
        .slice(1)
        .std(1) * Math.sqrt(52)
    )
  }

  const table_pdf = computed(() => {
    return table.value.reduce((acc, d) => {
      const volatility_yearly = volatility_yearly_poche(d)
      const group = d.group
      const position = d.position
      const allocation = d.allocation
      const returns = d.returns
      const returns_last_month = d.returns_last_month
      const benchmark = d.data.map('benchmark').mean()
      const benchmark_last_month = d.data.map('benchmark_last_month').mean()
      const pnl = d.pnl
      const contribution = d.contribution
      const aum_last_year_raw = d.data.sum('aum_last_year_raw')
      const pnl_realised_raw = d.data.sum('pnl_realised_raw')
      const pnl_latent_raw = d.data.sum('pnl_latent_raw')
      d.data.chunk(20).map(chunk => {
        acc.push({
          group,
          position,
          allocation,
          returns,
          returns_last_month,
          benchmark,
          benchmark_last_month,
          aum_last_year_raw,
          pnl,
          volatility_yearly,
          contribution,
          pnl_realised_raw,
          pnl_latent_raw,
          data: chunk,
        })
      })
      return acc
    }, [])
  })

  const allocationOptions = {
    labelKey: 'key',
    datasets: [
      {
        label: $root.t.fund,
        key: 'value',
        borderColor: 'rgb(0 145 153 / 100%)',
        backgroundColor: 'rgb(0 145 153 / 20%)',
        borderWidth: '1.5',
        fill: true,
        datalabels: true,
      },
    ],
    formatY: value => format('.3s' + $root.userflow.currency)(value),
  }
  const returnsOptions = {
    labelKey: 'key',
    datasets: [{ key: 'value', label: 'Fund', borderColor: 'none', borderRadius: '3', barThickness: '20' }],
    datalabels: false,
    labelFormatter: value => Math.round(value * 1000000) / 100 + 'bp',
    formatY: value => Math.round(value * 10000) + 'bp',
  }
  const riskOptions = line => {
    const all = table.value.find(v => v.group === line.type).data
    return {
      datasets: all.map((v, i) => {
        const color = getComputedStyle(document.body)
          .getPropertyValue(`--cat${i + 1}`)
          .trim()
        return {
          label: i + 1,
          key: 'serie-' + (i + 1),
          backgroundColor: v.isin === line.isin ? color : `rgba(0, 0, 0, 0.1)`,
          borderColor: color,
        }
      }),
      labelFormatter: value => Math.round(value * 1000000) / 100 + 'bp',
      formatX: value => Math.round(value * 10000) + 'bp',
      formatY: value => Math.round(value * 10000) + 'bp',
    }
  }
  const allocationData = line => {
    return $root.xf.contribution.data.filter(d => d.isin == line.isin).map(v => ({ key: v.date, value: v.position }))
  }
  const performanceData = line => {
    const dates = $root.dates.filter(d => d > $root.domain[0] && d<= $root.domain[1])
    const contrib_data = $root.xf.contribution.data.__.filter(d => d.isin == line.isin).filter(d => new Date(d.date) > new Date($root.domain[0]))
    return dates.map(d => {
      const first = contrib_data.__.first().benchmark || 1
      const last = contrib_data.find(c => c.date === d).benchmark || 1
      return {
        key: d,
        fund: line_returns_movements(line.isin, first_day_plus_1.value, d), // same logic as line_returns()
        benchmark: last / first - 1, // same logic as line_benchmark()
      }
    })
  }
  const returnsData = line => {
    // const last = $root.domain.last() || $root.x.contribution.last().date
    const groups = $root.xf.contribution.data
      .filter(d => d.isin == line.isin)
      .filter(d => new Date(d.date) > new Date($root.domain[0]))
      .group(d => new Date(d.date.slice(0, 7)).format('mon, year', $root.lang).capitalize())
    return groups.__.reduce((acc, grp, key) => {
      acc.push({ key, value: grp[0].date ? line_returns_movements(line.isin, grp[0].date, grp[grp.length - 1].date) : 0 })
      return acc
    }, [])
  }
  const riskData = line => {
    if (!line.isin) return
    const all = table.value.find(v => v.group === line.type).data
    const sizes = all.map(d => +d.position)
    const max_size = 5 / +sizes.max()
    return all.reduce((acc, d, i) => {
      acc['serie-' + (i + 1)] = [
        {
          x: +d.volatility || 0,
          y: +d.returns || 0,
          r: 3 + +d.position * max_size,
          line,
        },
      ]
      return acc
    }, {})
  }

  const export_name = 'table'
  const export_headers = [
    'type',
    'name',
    'manager',
    'liquidity',
    'allocation',
    'position',
    'aum_last_year_raw',
    'returns_last_month',
    'benchmark_last_month',
    'returns',
    'benchmark',
    'contribution',
    'pnl',
    'volatility_yearly',
    'pnl_realised_raw',
    'pnl_latent_raw',
    $root.profile.role === 'admin' && 'share_number',
  ].filter()

  const sum_columns = [
    'liquidity',
    'allocation',
    'position',
    'aum_last_year_raw',
    'returns_last_month',
    'returns',
    'benchmark',
    'benchmark_last_month',
    'contribution',
    'pnl',
    'volatility_yearly',
    'pnl_realised_raw',
    'pnl_latent_raw',
    $root.profile.role === 'admin' && 'share_number',
  ].filter()

  const export_data = computed(() => {
    const table_extend = table.value.map(t => ({ ...t, volatility_yearly: volatility_yearly_poche(t) }))
    const totals = {}
    const output = [export_headers.map(k => (k === 'benchmark' ? $root.t['benchmark_id'] : $root.t[k]))].concat(
      table_extend.flatMap(grp => {
        const header = export_headers.map(k => grp[k])
        const lines = grp.data.map(v =>
          export_headers.map(k => {
            if (k === 'share_number' && v.quantity !== false) return +v.quantity.toFixed(2)
            if (sum_columns.includes(k)) {
              if (!totals[k]) totals[k] = 0
              totals[k] += +v[k]
            }
            return v[k]
          }),
        )
        return [header].concat(lines)
      }),
    )
    // use the same 'total' logic as the pdf
    totals['liquidity'] = 0
    totals['volatility_yearly'] = volatility_yearly_poche(undefined)
    // same as getPerformance / getBenchmark in useKpis
    const contribution = $root.xf.contribution.data.filter(d => d.override == 'False')
    totals['returns'] = contribution.sum('pnl_wallet_shares') / $root.xf.performance.data.first().nav_before
    totals['benchmark'] =
      $root.xf.performance.data.__.filter(d => d.benchmark != 0).last().benchmark /
        $root.xf.performance.data.__.filter(d => d.benchmark != 0).first().benchmark -
      1
    const contribution_last_month = contribution.filter(d => d.date >= lastMonthFirst.value)
    totals['returns_last_month'] =
      contribution_last_month.sum('pnl_wallet_shares') / $root.xf.performance.data.find(d => d.date === lastMonthFirst.value).nav_before
    const perf_last_month = $root.xf.performance.data.__.filter(d => d.benchmark != 0).filter(d => d.date >= lastMonthFirst.value)
    totals['benchmark_last_month'] = perf_last_month.last().benchmark / perf_last_month.first().benchmark - 1

    const last_line = export_headers.map(h => (sum_columns.includes(h) ? totals[h] : ''))
    last_line[0] = $root.t['totals']
    return output.concat([last_line])
  })

  const export_csv = () => {
    const select = v => Object.fromEntries(export_headers.map((k, i) => [k, v[i]]))
    export_data.value.slice(1).map(select).dlCSV()
  }

  const export_excel = async () => {
    if (!window.XLSX) await inject('https://unpkg.com/xlsx@0.15.6/dist/xlsx.full.min.js')
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.aoa_to_sheet(export_data.value || [])
    XLSX.utils.book_append_sheet(workbook, worksheet)
    return XLSX.writeFile(workbook, export_name + '.xlsx')
  }

  watch(
    downloadable,
    downloadAction => {
      if (downloadAction) {
        export_excel()
        update_query({ action: null })
      }
    },
    { deep: true, immediate: true },
  )

  return {
    first,
    last,
    first_day_plus_1,
    lastMonthFirst,
    lastMonthFirstAfter,
    lastMonthLast,
    lastMonthLastAfter,
    PreviousFiveYearsBegin,
    slicesFirst,
    slicesLast,
    slicesLastYear,
    table,
    table_pdf,
    allocationOptions,
    allocationData,
    performanceData,
    returnsOptions,
    returnsData,
    riskOptions,
    riskData,
    export_data,
    export_csv,
    export_excel,
    performanceAvg,
    performanceMin,
    performanceMax,
    downloadable,
  }
}
